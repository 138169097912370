import { graphql } from "gatsby"
import NotesComponent from "../components/notes";

export default NotesComponent

export const query = graphql`
  query($formatString: String!) {
    allContentfulNote(sort: { fields: date, order: DESC }) {
      nodes {
        title
        body {
          childMdx {
            body
          }
        }
        date(formatString: $formatString)
        showTitle
      }
    }
  }
`
