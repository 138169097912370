// polish only
const mapping = {
  'ą': 'a',
  'ć': 'c',
  'ę': 'e',
  'ł': 'l',
  'ń': 'n',
  'ó': 'o',
  'ś': 's',
  'ż': 'z',
  'ź': 'z',
}

export function linkify(source) {
  return source
      .toLowerCase()
      .split('')
      .map(letter => mapping[letter] ?? letter)
      .join('')
      .replace(/\s/g, '-');
}
