import * as React from "react"
import SEO from "../../../components/seo";
import Layout from "../../../components/layout";
import {Box, Heading} from "theme-ui"
import {Flex} from "@theme-ui/components"
import {MDXRenderer} from "gatsby-plugin-mdx";
import {linkify} from "../utils/linkify";

type Props = {
  [key: string]: any
}

export default function NotesComponent({...props}: Props) {
  const notes = props.data.allContentfulNote.nodes;

  return <Layout>
    <SEO title="Notatki" />
    <Flex sx={{alignItems: `center`, justifyContent: `space-between`, flexFlow: `wrap`}}>
      <Heading as="h1" variant="styles.h1" sx={{marginY: 2}}>
        Notatki
      </Heading>
    </Flex>

    <Box sx={{my: 5, variant: `layout.content`}}>
      <section>
        {notes.map((note, i) => {
          const shouldShowTitle = note.showTitle !== false;

          return (
              <Box
                key={note.title} mb={4} sx={{
                variant: i + 1 !== notes.length && `dividers.bottom`,
                borderBottomColor: 'transparent',
                position: 'relative',
              }}>
                <Box mr={2} sx={{
                  display: 'inline-flex',
                  color: `secondary`,
                  a: {color: `secondary`},
                  fontSize: [1, 1, 2]
                }}>
                  <time>{note.date}</time>{shouldShowTitle ? ':' : null}
                </Box>
                <Heading
                    id={linkify(note.title)}
                    as={'h2'}
                    sx={{
                      minHeight: '1em',
                      fontSize: [1, 1, 2],
                      color: `secondary`,
                      display: 'inline-flex',
                      fontWeight: 'initial',
                      fontStyle: 'italic',
                      maxWidth: `calc(100vw - 2rem - 90px)`
                    }}>{shouldShowTitle && note.title}</Heading>
                <MDXRenderer sx={{mt: 0}}>{note.body.childMdx.body}</MDXRenderer>

              </Box>
          );
        })}
          </section>
          </Box>
          </Layout>

        }
